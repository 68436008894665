

import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { enableAnalytics, disableAnalytics } from '@/services/Firebase'

export default defineComponent({
  setup() {
    const cookiesEnabled = ref(localStorage.getItem('cookies') === 'consent')

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { t, cookiesEnabled }
  },

  methods: {
    enableCookies() {
      this.cookiesEnabled = true
      enableAnalytics()
    },

    disableCookies() {
      this.cookiesEnabled = false
      disableAnalytics()
    }
  }
})
