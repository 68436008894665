import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-544fd88f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = {
  class: "col-11 col-md-8",
  style: {"margin-top":"40px"}
}
const _hoisted_4 = { class: "legal-header" }
const _hoisted_5 = {
  class: "legal-text",
  style: {"white-space":"pre-line"}
}
const _hoisted_6 = {
  key: 0,
  class: "cookie_status"
}
const _hoisted_7 = {
  key: 1,
  class: "cookie_status"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('cookie_header')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('cookie_text')), 1),
        (_ctx.cookiesEnabled)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.t('cookie_status_enabled')), 1))
          : _createCommentVNode("", true),
        (!_ctx.cookiesEnabled)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.t('cookie_status_disabled')), 1))
          : _createCommentVNode("", true),
        (!_ctx.cookiesEnabled)
          ? (_openBlock(), _createElementBlock("a", {
              key: 2,
              class: "cookie_toggle",
              id: "cookie_enable",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.enableCookies && _ctx.enableCookies(...args)))
            }, _toDisplayString(_ctx.t('cookie_action_enable')), 1))
          : _createCommentVNode("", true),
        (_ctx.cookiesEnabled)
          ? (_openBlock(), _createElementBlock("a", {
              key: 3,
              class: "cookie_toggle",
              id: "cookie_disable",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.disableCookies && _ctx.disableCookies(...args)))
            }, _toDisplayString(_ctx.t('cookie_action_disable')), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}