<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-11 col-md-8" style="margin-top: 40px">
        <p class="legal-header">{{ t('cookie_header') }}</p>
        <p class="legal-text" style="white-space: pre-line">{{ t('cookie_text') }}</p>
        <p class="cookie_status" v-if="cookiesEnabled">{{ t('cookie_status_enabled') }}</p>
        <p class="cookie_status" v-if="!cookiesEnabled">{{ t('cookie_status_disabled') }}</p>
        <a class="cookie_toggle" id="cookie_enable" v-if="!cookiesEnabled" @click="enableCookies">{{ t('cookie_action_enable') }}</a>
        <a class="cookie_toggle" id="cookie_disable" v-if="cookiesEnabled" @click="disableCookies">{{ t('cookie_action_disable') }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { enableAnalytics, disableAnalytics } from '@/services/Firebase'

export default defineComponent({
  setup() {
    const cookiesEnabled = ref(localStorage.getItem('cookies') === 'consent')

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { t, cookiesEnabled }
  },

  methods: {
    enableCookies() {
      this.cookiesEnabled = true
      enableAnalytics()
    },

    disableCookies() {
      this.cookiesEnabled = false
      disableAnalytics()
    }
  }
})
</script>

<style scoped lang="sass">
.legal-header
  font-size: 1.3em
  font-weight: 600
  text-align: center

.legal-text
  text-align: center

.cookie_status
  color: $color_on_background_medium
  text-align: center

.cookie_toggle
  cursor: pointer
  border-radius: 12px
  display: table
  padding: 6px 12px 6px 12px
  margin-left: auto
  margin-right: auto
  text-decoration: none

#cookie_disable
  background-color: $color_secondary
  color: $color_on_background

#cookie_enable
  background-color: $color_secondary_variant
  color: $color_background
</style>

<i18n>
{
  "de": {
    "cookie_header": "Cookie Richtlinie",
    "cookie_status_enabled": "Status: Cookies erlaubt",
    "cookie_status_disabled": "Status: Cookies deaktiviert",
    "cookie_action_enable": "Cookies erlauben",
    "cookie_action_disable": "Cookies deaktivieren",
    "cookie_text": "Diese Webseite verwendet Cookies. Wir verwenden Cookies, um die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.\n\nCookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu gestalten. Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir Ihre Erlaubnis. Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die auf unseren Seiten erscheinen."
  }
}
</i18n>
